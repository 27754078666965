@use '../../styles/colors';
@use '../../styles/fonts';
@import '../../styles/breakpoints';

.modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background-color: colors.$light;
  z-index: 1001;
  border: 16px solid colors.$light;
  margin-top: 30px;

  @include medium {
    display: block;
    margin: 0 auto;
    width: 1260px;
    height: 646px;
    border: 32px solid colors.$light;
    border-right-width: 54px;
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 370px 1fr;
  grid-row-gap: 35px;

  @include medium {
    display: grid;
    grid-template-columns: 543px 564px;
    grid-template-rows: 582px;
    grid-column-gap: 67px;
  }
}

.imageWrapper {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  margin: auto;
}

.imageTop, .imageBottom {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

@keyframes toteBagFadeInOut {
  0% {
    opacity:1;
  }
  25% {
    opacity:1;
  }
  75% {
    opacity:0;
  }
  100% {
    opacity:0;
  }
}

.imageTop {
  animation-name: toteBagFadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-direction: alternate;
  -webkit-animation-name: toteBagFadeInOut;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
  -webkit-animation-direction: alternate;
}

.contentWrapper {
  font-family: fonts.$body;
  display: block;
  margin: auto 0;
}

.closed {
  display: none;
}
