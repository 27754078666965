@use '../../styles/colors';
@use '../../styles/fonts';

.button, .iconButton {
    color: colors.$light;
    display: inline-block;
    border: 2px solid colors.$light;
    border-radius: 50px;
    font-family: fonts.$body;
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: pointer;
    overflow: hidden;
    max-height: 60px;
    display: flex;
    justify-content: center;
    min-width: 190px;
    background-color: rgba(0, 0, 0, 0.2);
    position: relative;
    margin: 0 10px 12px 10px;

    span {
        display: block;
        position: relative;
        top: 0;
        opacity: 1;
        transition: transform .2s ease-in-out, opacity .1s ease-in-out .2s;

        &:nth-child(2) {
            top: 10px;
            position: absolute;
            opacity: 0;
            transition: opacity .1s ease-in-out .2s;
        }
    }
}

.iconButton {
    background-color: colors.$light;
    color: colors.$dark;
    span {
        right: 14px;
        &:nth-child(2) {
            right: 64px;
        }
    }

    svg {
        position: absolute;
        right: 10px;
        top: 5px;
    }
}