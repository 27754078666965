@use '../../styles/colors';
@use '../../styles/fonts';
@import "../../styles/breakpoints";

.TopMenu {
    z-index: 10;
    position: relative;
}

.logo {
    z-index: 100;
    position: absolute;
    top: 21px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 162px;
    height: 91px;

    @include medium {
        top: 35px;
        width: 200px;
        height: 111.83px;
        transform: translate(-60%, 0);
    }
}

.TopMenuButton {
    position: absolute;
    left: 23.5px;
    top: 20.5px;
    z-index: 1000;
    background: transparent;
    border: none;

    .icon {
        width: 25px;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;

        span {
            display: block;
            margin-top: 5px;
            height: 3px;
            width: 100%;
            background: colors.$light;
            border-radius: 2px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;
            position: relative;
        }

 span:nth-child(1) {
        top: 0px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }
      
      span:nth-child(2) {
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }
      
   span:nth-child(3) {
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }
      
    }
}

a {
    transition-delay: 0.2s;
    transition: opacity 0.2s ease-in-out 0.2s;
    opacity: 0;
    
}

.Nav {
    opacity: 0;
    height: 0;
    width: 100vw;
    position: fixed;
    top: 0;
    background-color: colors.$dark;
    transition: height 0.3s ease-in-out;
}

.BtnWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 140px;
    top: 40vh;
    position: relative;
}

.TopMenuActive {
    z-index: 10;
    position: relative;

    a {
        opacity: 1;
        display:block
    }

    .Nav {
        opacity: 1;
        height: var(--navHeight); 
        display: flex;
        flex-direction: column;
    }

    button .icon span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -1px;
    }
    
    button .icon span:nth-child(2) {
    width: 0%;
    opacity: 0;
    }
    
    button .icon span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    }
}


@include medium {
    button {
        display: none;
    }

    .Nav {
        opacity: 1;
        background-color: transparent;
        position: relative;
        display:block;
    }

    .BtnWrapper {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-evenly;
        top: 63px;
    }

    a {
        opacity: 1;
        display: inline-block;
    }
}