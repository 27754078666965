@use '../../styles/colors';
@use '../../styles/fonts';
@import '../../styles/breakpoints';

.bodyLight {
  color: colors.$light;
}

.bodyDark {
  color: colors.$dark;
}

.bodyLight, .bodyDark {
  font-family: fonts.$body;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: left;

  @include medium {
    font-size: 16px;
    line-height: 28px;
  }
}