@use '../../styles/colors';
@use '../../styles/fonts';
@import '../../styles/breakpoints';

.NavLink {
  font-family: fonts.$heading;
  color: colors.$light;
  text-transform: uppercase;
  font-size: 19px;
  line-height: 40px;
  font-weight: 900;
  text-decoration: none;
  position: relative;
  display: block;
  width: 129px;
  text-align: left;
  pointer-events: var(--pointerEvents);

  &::before {
    content: '';
    width: var(--width);
    position: absolute;
    bottom: 0;
    border-bottom: 3px solid colors.$light;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    &::before {
      width: var(--maxWidth);
    }
  }

  &:hover,
  &:visited {
    color: colors.$light;
  }
}

@include medium {
  .NavLink {
    font-family: fonts.$heading;
    color: colors.$light;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 40px;
    font-weight: 900;
    text-decoration: none;
    position: relative;
    display: inline-block;

    &::before {
      content: '';
      width: var(--width);
      position: absolute;
      bottom: 0;
      border-bottom: 3px solid colors.$light;
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      &::before {
        width: var(--maxWidth);
      }
    }

    &:hover,
    &:visited {
      color: colors.$light;
    }
  }
}
