@use '../../styles/colors';
@use '../../styles/fonts';
@import '../../styles/breakpoints';

.bodyTopMargin {
  margin: 14px 0 0 0;

  @include medium {
    margin: 21px 0 0 0;
  }
}

.bodyNoMargin {
  margin: 0;
}

.bodyTopMargin, .bodyNoMargin {
  font-family: fonts.$body;
  color: colors.$light;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;

  @include medium {
    font-size: 21px;
    line-height: 36px;
  }
}