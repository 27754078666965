@use '../styles/colors';
@import '../styles/breakpoints';

.container,
.containerWithModal {
  display: block;
  background-color: colors.$dark;
  overflow-x: hidden;

  @include medium {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 818px;
  }
}

.backgroundVideo {
  position: relative;
  width: 100%;
  height: 680px;
  object-fit: cover;

  @include medium {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 798px;
  }
}

.videoContainer {
  display: block;
  text-align: center;
  width: 100%;
  max-height: 680px;
  min-height: 80vh;

  @include medium {
    height: inherit;
    width: 100%;
  }
}

.modalContainer {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.closeButton {
  display: block;
  align-self: flex-end;
  margin-top: 17.5px;
  margin-right: 23.5px;
  background-color: transparent;
  border: 0;
  z-index: 100;

  &:hover {
    cursor: pointer;
  }

  @include medium {
    margin-top: 33px;
    margin-right: 33px;
  }
}

.imageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  @include medium {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.image {
  width: 334px;
  height: 243px;
  margin-top: 20px;

  @include medium {
    width: 30%;
    max-width: 450px;
    height: 297px;
    margin-right: 20px;
  }
}

.missionContainer {
  display: block;
  width: 100%;
  color: colors.$light;

  @include medium {
    width: 754px;
    justify-self: center;
    margin-top: 110px;
  }
}

.missionTitle {
  display: block;

  @include medium {
    margin: 0 auto;
    width: 666px;
  }
}

.missionContent {
  margin: 0 20px 53px 20px;

  @include medium {
    margin: 0 0 109px 0;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: var(--top);
  width: 100%;

  @include medium {
    flex-direction: row;
    justify-content: center;
    top: 626px;
  }
}

.containerWithModal {
  &::before {
    content: '';
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    text-align: left;
    background: rgba(0, 0, 0, 0.85);
    z-index: 100;
  }
}
