@use '../../styles/fonts';
@use '../../styles/colors';

.Footer {
    font-family: fonts.$heading;
    font-weight: 900;
    font-size: 13px;
    line-height: 17.2px;
    letter-spacing: .01em;
    text-transform: uppercase;
    color: colors.$light;
    background-color: colors.$black;
    min-height: 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: auto; 
}