@use '../../styles/colors';
@import '../../styles/breakpoints';

.modal {
  position: relative;
  top: 50%;
  margin-top: 30px;
  width: 100%;
  max-width: 1260px;
  height: auto;
  background-color: colors.$light;
  z-index: 1001;
  border: 18px solid colors.$light;
  border-left: 0;
  border-right: 0;

  @include medium {
    display: block;
    margin: 0 auto;
    max-width: 1260px;
    border: 32px solid colors.$light;
  }
}

.videoWrapper {
  display: block;
  width: 100%;
  height: 100%;
  margin: auto;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.closed {
  display: none;
  video {
    display: none;
  }
}

.buttonWrapper {
  position: absolute;
  display: inline-block;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
}

.button {
  cursor: pointer;
}
