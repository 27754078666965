@use '../../styles/colors';
@use '../../styles/fonts';

.subtitleLight {
  color: colors.$light;
}

.subtitleDark {
  color: colors.$dark;
}

.subtitleLight, .subtitleDark {
  font-family: fonts.$heading;
  font-weight: 900;
  font-size: 28px;
  line-height: 28px;
  text-align: left;
}
