@use '../../styles/fonts';
@use '../../styles/colors';
@import '../../styles/breakpoints';

.ImageCard {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;
  justify-content: center;
  align-items: center;

  .Title {
    font-family: fonts.$heading;
    color: colors.$light;
    font-weight: 900;
    font-size: 3.875rem;
    line-height: 82.03px;
    letter-spacing: 0.02em;
    margin-bottom: unset;
    text-align: center;
  }

  .Icon {
    position: relative;
    width: 125px;
    height: 50px;
    margin-top: 12px;
  }

  .imageContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 3rem;

    @include medium {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
  }

  .image {
    width: 334px;
    height: auto;
    margin-top: 20px;

    @include medium {
      width: 100%;
      max-width: 450px;
      margin-right: 20px;
    }
  }
}
