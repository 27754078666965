@use '../../styles/fonts';
@use '../../styles/colors';
@import '../../styles/breakpoints';

.titleShoutOut {
  font-size: 40px;
  &:after {
    content: "";
    display: block;
    margin: 0 auto;
    border-bottom: 6px solid colors.$light;
    width: 192px;
  }

  @include medium {
    font-size: 72px;
    &:after {
      content: "";
      display: block;
      margin: 0 auto;
      border-bottom: 6px solid colors.$light;
      width: 250px;
    }
  }
}

.titleMission {
  font-size: 40px;
  &:after {
    content: "";
    display: block;
    margin: 0 auto;
    border-bottom: 6px solid colors.$light;
    width: 192px;
    position: relative;
    top: -5px;
  }

  @include medium {
    font-size: 85px;
    &:after {
      content: "";
      display: block;
      margin: 0 auto;
      border-bottom: 6px solid colors.$light;
      width: 300px;
      position: relative;
      top: 20px;
    }
  }
}

.titleShoutOut, .titleMission {
  font-family: fonts.$heading;
  color: colors.$light;
  font-weight: 900;
  line-height: 105px;
  letter-spacing: 0.02em;
  text-align: center;
}
