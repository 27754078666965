@use '../styles/colors';
@use '../styles/fonts';
@import '../styles/breakpoints';

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 140px 1fr;
  overflow-x: hidden;

  @include medium {
    grid-template-rows: 190px 1fr;
  }
}

.shoutOuts {
  margin: 0 21px 0 21px;
  justify-self: center;
  color: colors.$light;

  @include medium {
    margin: 0 122px 0 122px;
  }
}

.shoutOutTitle {
  width: 100%;
  display: block;
  margin: 0 auto;
}

.shoutOutContainer {
  margin: 0 0 139px 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;

  @include large {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 44px;
  }
}

.shoutOutContent {
  margin-top: 37px;

  @include medium {
    margin-top: 76px;
  }
}