@use '../../styles/fonts';
@use '../../styles/colors';
@import '../../styles/breakpoints';

.FilmTabs {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 129px;
  width: 100%;
  max-width: 70rem;
  gap: 17px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 22.75rem;
    padding: 1rem;
    justify-content: center;
  }

  .Preview {
    position: relative;
    display: flex;
    outline: none;
    border: none;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    gap: 20px;
    padding: 10px;

    &:hover {
      background-color: #ff7a0f;
    }

    .Thumbnail {
      position: relative;
      width: 109px;
      height: auto;
    }

    .tabText {
      font-family: fonts.$body;
      font-weight: 800;
      font-size: 20px;
      line-height: 27.24px;
      color: colors.$light;
    }
  }
}
