$breakpoint-medium: 768px;
$breakpoint-large: 1200px;

@mixin medium {
  @media (min-width: $breakpoint-medium) {
    @content;
  }
}

@mixin large {
  @media (min-width: $breakpoint-large) {
    @content;
  }
}
