@use '../../styles/fonts';
@use '../../styles/colors';
@import '../../styles/breakpoints';

.CustomVideo {
  position: relative;
  width: 100%;
  height: 680px;
  object-fit: cover;

  @include medium {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 798px;
  }
}